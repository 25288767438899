import React, { useState } from 'react';
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    HStack,
    IconButton,
    Image,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    PopoverArrow,
    PopoverCloseButton
} from '@chakra-ui/react';
import { FcEmptyTrash, FcNext, FcPrevious } from 'react-icons/fc';
import Editor from './editor';
import axios from 'axios';
import { useToast } from '@chakra-ui/react'

const edjsHTML = require("editorjs-html");

const example = {
    "time": 1712285177731,
    "blocks": [
        {
            "id": "6Kxl8RvjBM",
            "type": "paragraph",
            "data": {
                "text": "Editor.js"
            }
        },
        {
            "id": "wQgPIxgOTs",
            "type": "header",
            "data": {
                "text": "This is the title",
                "level": 2
            }
        },
        {
            "id": "T8lsERvSOD",
            "type": "paragraph",
            "data": {
                "text": "Free block-style editor with&nbsp;a&nbsp;universal <b>JSON&nbsp;</b>output"
            }
        },
        {
            "id": "ZIIyQbU07x",
            "type": "list",
            "data": {
                "style": "unordered",
                "items": [
                    "Free and open-source",
                    "Clean UI/UX<br>",
                    "Simple API driven<br>",
                    "Powered by Code<br>"
                ]
            }
        },
        {
            "id": "5OU3IHVwYO",
            "type": "heading",
            "data": {
                "text": "Table Test",
                "level": 3
            }
        },
        {
            "id": "kD1zm9YpAC",
            "type": "table",
            "data": {
                "withHeadings": false,
                "content": [
                    [
                        "Title",
                        "Captions"
                    ],
                    [
                        "D1",
                        "D2"
                    ]
                ]
            }
        }
    ],
    "version": "2.29.1"
}

const NotePage = ({ data }) => {
    const { images, altText, note, questions, raw, tags, explanations } = data;
    const [isActive, setIsActive] = React.useState(false);
    const [pageRatio, setPageRatio] = React.useState(2);
    const [notemd, setNotemd] = useState(note)
    const [noteUpdate, setNoteUpdate] = useState(note)
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const toast = useToast()

    async function generateNote(raw) {
        try {
            setIsLoading(true)
            const response = await fetch('https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=AIzaSyBp9Dx1CqmRZa6G_bmuBfSKUfbUVXvXEFo'
                , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        contents: [{
                            parts: [{
                                text: `User gives a text from their lecture notes,\n- if the note contains a topic and few points, explain the topic with 2 sentences and each point with 2 or more sentences including every neccassary thing about each point.\n- if the note contains a paragraph or description, separate the description in to points and do the same like above.\n- each explanation should be memorable, understandable and should have precise data and information. if the explanation is not understandable, write another sentence with an example to make it easier to understand.\n- if the same point or keyword found on a previous answer, do not explain the point or key word. need detailed long text in point form. no need to add titles for sentences or paragraphs. respond in markdown. /n lecture note : ${raw}`
                            }]
                        }]
                    })
                });
            const data = await response.json();
            console.log(data);
            const extractedText = data.candidates[0].content.parts[0].text;
            console.log(extractedText)
            setNotemd(extractedText)
            setNoteUpdate(extractedText)
            toast({
                title: "Note generated",
                description: "The note has been generated successfully",
                status: "success",
                duration: 1000,
                isClosable: true,
                position:"bottom-right"

            })
        } catch (error) {
            console.error(error);
            toast({
                title: "An error occurred.",
                description: "Unable to generate note",
                status: "error",
                duration: 1000,
                isClosable: true,
                position:"bottom-right"

            })
        } finally {
            setIsLoading(false)
        }
    }
    
    async function updateNote(id) {
        try {
            setIsLoading(true)
            const response = await axios.put('https://note-pro-d1-prisma.note-pro.workers.dev/notes?id=' + id, {
                note: noteUpdate,
                id: id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response.data);
            toast({
                title: "Note updated",
                description: "The note has been updated successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
                position:"bottom-right"
            })
        } catch (error) {
            console.error(error);
            toast({
                title: "An error occurred.",
                description: "Unable to update note",
                status: "error",
                duration: 3000,
                isClosable: true,
                position:"bottom-right"

            })
        } finally {
            setIsLoading(false)
        }
    }

    // curl --location --request DELETE 'https://note-pro-d1-prisma.note-pro.workers.dev/notes?id=1'
    async function deleteNote(id) {
        try {
            setIsLoading(true)
            const response = await axios.delete('https://note-pro-d1-prisma.note-pro.workers.dev/notes?id=' + id);
            console.log(response.data);
            toast({
                title: "Note deleted",
                description: "The note has been deleted successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
                position:"bottom-right"
            })
            setIsDeleted(true)
        } catch (error) {
            console.error(error);
            toast({
                title: "An error occurred.",
                description: "Unable to delete note",
                status: "error",
                duration: 3000,
                isClosable: true,
                position:"bottom-right"

            })
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <Container display={isDeleted ? 'none':'block'} borderRadius={16} maxW={'8xl'} p={{ base: 2, md: "12" }} onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)} bgColor={useColorModeValue(isActive ? 'gray.50' : 'gray.100')}
            transition={'transform 0.2s, opacity 0.2s, background-color 0.2s'}>
            <Box
                marginTop={{ base: '1', sm: '5' }}
                display="flex"
                flexDirection={{ base: 'column', sm: 'row' }}

                justifyContent="space-between">
                <Box
                    display="flex"
                    flex="1"
                    marginRight="3"
                    position="relative"
                    alignItems="start">
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="1"
                        marginLeft={{ base: '0', sm: '5%' }}
                        marginTop="5%">
                        <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            {JSON.parse(images).map((image, index) => (
                                <Image
                                    key={index}
                                    borderRadius="lg"
                                    src={image}
                                    alt={altText}
                                    objectFit="contain"
                                />
                            ))}
                        </Box>
                        <Box>
                            <IconButton onClick={() => setPageRatio(pageRatio + 1)} icon={<FcPrevious />} variant={'ghost'} />
                            <IconButton onClick={() => setPageRatio(pageRatio - 1)} icon={<FcNext />} variant={'ghost'} />
                            <IconButton onClick={() => deleteNote(data.id)} icon={<FcEmptyTrash />} variant={'ghost'} />
                        </Box>
                    </Box>
                    <Box zIndex={0} width="100%" position="absolute" height="100%">
                        <Box
                            bgGradient={useColorModeValue(
                                `radial(${isActive ? 'green' : 'orange'}.600 1px, transparent 1px)`,
                                `radial(${isActive ? 'green' : 'orange'}.300 1px, transparent 1px)`,
                            )}
                            backgroundSize="20px 20px"
                            opacity="0.4"
                            height="100%"
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flex={pageRatio}
                    flexDirection="column"
                    justifyContent="start"
                    marginTop={{ base: '3', sm: '0' }}>

                    <Tabs position="relative" variant="unstyled" colorScheme="orange">
                        <TabList>
                            <Tab>Note</Tab>
                            <Tab>Questions</Tab>
                            <Tab>Raw</Tab>
                        </TabList>
                        <TabIndicator
                            mt="-1.5px"
                            height="2px"
                            bg="orange.500"
                            borderRadius="1px"
                        />
                        <TabPanels>
                            <TabPanel p={0}>
                                <Editor markdown={notemd} readOnly={true} onChange={(value) => {
                                    setNoteUpdate(value)
                                }} />
                                <Explanations data={JSON.parse(explanations)} />
                                <Button mr={2} isDisabled={isLoading} onClick={() => {
                                    updateNote(data.id)
                                }} >⬆️ Save</Button>
                                <Button isLoading={isLoading} onClick={() => {
                                    generateNote(raw)
                                }}>✨ Note</Button>
                            </TabPanel>
                            <TabPanel>
                                {JSON.parse(questions).map((q, i) => {
                                    return <Box key={i} marginTop={i > 0 ? 2 : 0}>
                                        <Text fontWeight="bold">{q.title}</Text>
                                        <Text>{q.content}</Text>
                                    </Box>
                                })}
                            </TabPanel>
                            <TabPanel>
                                <Text align={'justify'}>{raw}</Text>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                </Box>
            </Box>
            <Box
                transition={'opacity 0.5s'}
                opacity={isActive ? 1 : 0}
                marginTop="4"
            >
                {isActive && <BlogTags tags={tags.split(', ')} />}
            </Box>
        </Container>
    );
};

const BlogAuthor = (props) => {
    return (
        <HStack marginTop="2" spacing="2" alignItems="center">
            <Image
                borderRadius="full"
                boxSize="40px"
                src="https://100k-faces.glitch.me/random-image"
                alt={`Avatar of ${props.name}`}
            />
            <Text fontWeight="medium">{props.name}</Text>
            <Text>—</Text>
            <Text>{props.date.toLocaleDateString()}</Text>
        </HStack>
    );
};

const BlogTags = ({ tags, marginTop }) => {
    return (
        <Wrap spacing="2" marginTop={marginTop}>
            {tags.map((tag, index) => (
                <WrapItem key={index}>
                    <Tag size="md" variant="solid" colorScheme="orange">
                        {tag}
                    </Tag>
                </WrapItem>
            ))}
        </Wrap>
    );
};

function Explanations({ data }) {
    return <div>
        {data.map(e => {
            return <Popover>
                <PopoverTrigger>
                    <Button variant={'ghost'}>{e.text}</Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>{e.text}</PopoverHeader>
                    <PopoverBody>{e.explanations[0]}</PopoverBody>
                </PopoverContent>
            </Popover>
        })}
    </div>
}

export default NotePage;

// curl \
//   -H 'Content-Type: application/json' \
//   -d '{"contents":[{"parts":[{"text":"Write a story about a magic backpack"}]}]}' \
//   -X POST 'https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=YOUR_API_KEY'

