import { Avatar, Button, Flex, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue, useColorMode, Box, } from '@chakra-ui/react';
import { FiBell, FiChevronDown } from 'react-icons/fi';
import { CiBrightnessUp } from "react-icons/ci";
import { useEffect, useState } from 'react';
import { API_URL } from '../config/constants';
import axios from 'axios';

export default function Header({ state, updateState }) {
    const { colorMode, toggleColorMode } = useColorMode();
    const [courses, setCourses] = useState([])

    // async function fetchCourses() {
    //     const response = await fetch(`${API_URL}/courses?level=${state.level}&semester=${state.semester}`)
    //     const data = await response.json()
    //     setCourses(data)
    // }

    async function fetchCourses() {
        const response = await axios.get(`${API_URL}/courses?level=${state.level}&semester=${state.semester}`)
        setCourses(response.data)
    }
    useEffect(() => {
        fetchCourses()
    }, [])



    return <Flex
        px={{ base: 4, md: 8 }}
        height="14"
        alignItems="center"
        bg={useColorModeValue('white', 'gray.900')}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        justifyContent={{ base: 'space-between' }}
        position="sticky"
        top="0"
        zIndex="50"
    >
        <Text display={{ base: 'flex' }} fontSize="2xl" fontFamily="monospace" fontWeight="bold">
            Note Pro
        </Text>
        <HStack spacing={{ base: '0', md: '6' }}>
            {/* button to change dark mode */}
            <IconButton variant={'ghost'}
                onClick={() => {
                    toggleColorMode();
                }}
                icon={<CiBrightnessUp />}
            />
            <Flex alignItems={'center'}>
                <Menu zIndex={52}>
                    <MenuButton as={Button} rightIcon={<FiChevronDown />} variant={'ghost'} overflow={'clip'} maxW={32}>
                    {state.course ? courses.find(course => course.id === state.course).title.split(' ').map(word => word[0].toUpperCase()).join('') : "Select Course"}
                        {/* <small>[{c.title}]</small> */}
                        <br/>
                       <small>
                        {state.course ? courses.find(course => course.id === state.course).subjectCode : ""}</small>
                    </MenuButton>
                    <MenuList >
                        {courses.map(c => {
                            return <MenuItem key={c.id} onClick={() => {
                                console.log(c)
                                updateState({ ...state, course: c.id })
                            }}>{c.title.split(' ').map(word => word[0].toUpperCase()).join('') }</MenuItem>
                        })}
                    </MenuList>
                </Menu>
                {/* <Menu>
                    <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                        <HStack>
                            <Avatar
                                size={'sm'}
                                src={
                                    'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                                }
                            />
                            <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                                <Text fontSize="sm">Justina Clark</Text>
                                <Text fontSize="xs" color="gray.600">
                                    Admin
                                </Text>
                            </VStack>
                            <Box display={{ base: 'none', md: 'flex' }}>
                                <FiChevronDown />
                            </Box>
                        </HStack>
                    </MenuButton>
                    <MenuList bg={useColorModeValue('white', 'gray.900')} borderColor={useColorModeValue('gray.200', 'gray.700')}>
                        <MenuItem>Profile</MenuItem>
                        <MenuItem>Settings</MenuItem>
                        <MenuItem>Billing</MenuItem>
                        <MenuDivider />
                        <MenuItem>Sign out</MenuItem>
                    </MenuList>
                </Menu> */}
            </Flex>
        </HStack>
    </Flex>
}