import Layout from "./components/layout";
import NotePage from "./components/note_page";
import NoteHeader from "./components/note_header";
import { Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import axios from 'axios'
import { API_URL } from "./config/constants";
function App() {
  const [studentState, setStudentState] = useState({
    level: 200,
    semester: 2,
    course: null,
    lesson:null
  })
  

  useEffect(()=>{
    if(studentState.lesson){
      fetchLessons()
    }
  },[studentState.lesson])

  const [notes, setNotes] = useState([])

  async function fetchLessons() {
    const response = await axios.get(`${API_URL}/notes?lessonId=${studentState.lesson}`)
    console.log(response.data)
    setNotes(response.data)
  }
  

  return (
    <Layout state={studentState}  updateState={setStudentState}>
      {/* {/* <Divider my={8}/> */}
      {notes.map((page, index) => (
        <NotePage data={page} key={index} />
      ))}
    </Layout>
  );
}

export default App;