import React, { useState } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import Header from './header';
import Toolbar from './toolbar';

const Layout = ({ state, updateState, children }) => {
    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <Header state={state} updateState={updateState} />
            <Toolbar state={state} updateState={updateState}/>
            <Box p="4">
                {children}
            </Box>
        </Box>
    );
};

export default Layout;
