import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, useColorModeValue, useDisclosure, Box } from '@chakra-ui/react';
import axios from 'axios';
import { FiChevronDown } from 'react-icons/fi';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react';
import { FcAssistant, FcRefresh } from "react-icons/fc";
import Editor from './editor';
import ExplainTool from './aitools/explain';
import { API_URL } from '../config/constants';
export default function Toolbar({state, updateState}) {
    const [lessons, setLessons] = useState([])
    useEffect(() => {
        fetchLessons()
    }, [state])

    async function fetchLessons() {
        if(state.course){
            const response = await axios.get(`${API_URL}/lessons?courseId=${state.course}`)
            setLessons(response.data)
        }
    }
    return <Flex
        px={{ base: 4, md: 8 }}
        height="12"
        alignItems="center"
        bg={useColorModeValue('white', 'gray.900')}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        justifyContent={{ base: 'space-between' }}
        position="sticky"
        top="14"
        zIndex="10"
    >
        <Menu >
            <MenuButton as={Button} rightIcon={<FiChevronDown />} variant={'ghost'} isDisabled={!state.course}>
                {state.lesson ? lessons.find(lesson => lesson.id === state.lesson).title : "Select Lesson"}
            </MenuButton>
            <MenuList>
                {lessons.map(lesson => {
                    return <MenuItem key={lesson.id} onClick={() => {
                        updateState({ ...state, lesson: lesson.id })
                    }}>{lesson.title}</MenuItem>
                })}
            </MenuList>
        </Menu>
        <Flex>
            <ExplainTool />
            <Box w={2} display={{ base: "none", md: "block" }}  />
            <Translate />
            {/* <Box w={2} display={{ base: "none", md: "block" }}  />
            <EditContent /> */}
        </Flex>
    </Flex>
}


function Translate() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedText, setSelectedText] = useState('');

    useEffect(() => {
        let text = "";
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type !== "Control") {
            text = document.selection.createRange().text;
        }
        setSelectedText(text);
    }, [isOpen]);

    return (
        <>
            <Button display={{ base: "none", md: "block" }}  onClick={onOpen} rightIcon={<FcRefresh />} variant={'outline'}>Translate</Button>

            <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset='slideInBottom' size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Translate</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedText}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}




function EditContent() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button display={{ base: "none", md: "block" }}  onClick={onOpen} rightIcon={<FcAssistant />} variant={'outline'}>Edit</Button>

            <Drawer onClose={onClose} isOpen={isOpen} size={'xl'}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{`Editing Content`}</DrawerHeader>
                    <DrawerBody>
                        <Editor/>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
}