import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Divider } from '@chakra-ui/react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
  Flex,
  IconButton
} from '@chakra-ui/react'
import { FcAssistant } from 'react-icons/fc';
import { MdAutoAwesome } from "react-icons/md";
import { FcSynchronize, FcMultipleInputs } from "react-icons/fc";
import { VscRefresh, VscSave } from "react-icons/vsc";
import Markdown from 'react-markdown'

function ExplainTool() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedText, setSelectedText] = useState('');

  const [result, setResult] = useState('');

  async function getExplanation(text = '') {

    if (text == '') {
      if (window.getSelection) {
        text = window.getSelection().toString();
      } else if (document.selection && document.selection.type !== "Control") {
        text = document.selection.createRange().text;
      }
      if (text == '') {
        alert('No text selected')
        return
      }
    }
    try {
      setIsLoading(true)
      const response = await fetch('https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=AIzaSyBp9Dx1CqmRZa6G_bmuBfSKUfbUVXvXEFo'
        , {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            contents: [{
              parts: [{
                text: `Explain this using 200 words: ${text}`
              }]
            }]
          })
        });
      const data = await response.json();
      console.log(data);
      const extractedText = data.candidates[0].content.parts[0].text;
      setResult(extractedText);

      setSelectedText(text);
      // set random result
      // setResult(Math.random() > 0.5 ? extractedText1 : extractedText2);
      onOpen();
    } catch (error) {
      alert(error)
    } finally {
      setIsLoading(false)
    }
  }

  // useEffect(() => {
  //   if (isOpen) {
  //     let text = "";
  //     if (window.getSelection) {
  //       text = window.getSelection().toString();
  //     } else if (document.selection && document.selection.type !== "Control") {
  //       text = document.selection.createRange().text;
  //     }
  //     if (text != '') {
  //       setSelectedText(text);
  //     } else {
  //       onClose()
  //     }
  //   }

  // }, [isOpen]);

  return (
    <>
      <Button onClick={() => (
        getExplanation()
      )} rightIcon={<FcAssistant />} variant={'outline'} isLoading={isLoading}>Explain</Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset='slideInBottom' size={'xl'} scrollBehavior='inside'>
      <ModalOverlay
      backdropFilter='auto'
      backdropBlur='8px'
    />
        <ModalContent>
          <ModalHeader>Explain "{selectedText}"</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            textAlign={'justify'}>
              <Markdown>{result}</Markdown>
            <Flex fontSize={12} fontWeight={'normal'} alignItems={'center'} >
              <MdAutoAwesome />
              <Text
                mx={2}
                style={{
                  background: "linear-gradient(74deg, rgb(66, 133, 244) 0px, rgb(155, 114, 203) 9%, rgb(217, 101, 112) 30%, rgb(217, 101, 112) 24%, rgb(155, 114, 203) 55%, rgb(66, 133, 244) 84%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent"
                }}
              >
                Generated by Google Gemini
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent={'space-between'}>

            <Flex>
              <IconButton colorScheme='orange' icon={<VscSave />} variant={'ghost'} isDisabled={isLoading}></IconButton>
              <IconButton colorScheme='orange' icon={<VscRefresh />} variant={'ghost'} onClick={() => getExplanation(selectedText)} isLoading={isLoading}></IconButton>
            </Flex>
            <Button colorScheme='orange' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ExplainTool;