import '@mdxeditor/editor/style.css'
import { MDXEditor, BlockTypeSelect, linkDialogPlugin, Separator, markdownShortcutPlugin, InsertTable, tablePlugin, headingsPlugin, CodeToggle, CreateLink, InsertImage, imagePlugin, ListsToggle, listsPlugin, quotePlugin, toolbarPlugin, UndoRedo, BoldItalicUnderlineToggles } from '@mdxeditor/editor'
import { Box, Button } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { FiEdit2 } from 'react-icons/fi';
import { useColorMode } from '@chakra-ui/react';

function Editor({ markdown, onChange }) {
    const [readOnly, setReadOnly] = useState(true);
    const ref = useRef(null)

    const { colorMode } = useColorMode();
    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `.mdxeditor-toolbar { display: ${readOnly ? 'none' : 'flex'} !important; z-index: 10000; }`;
        document.head.appendChild(style);
    }, [readOnly]);

    useEffect(() => {
        if (ref.current) {
            ref.current.setMarkdown(markdown)
        }
    }, [markdown])


    return <Box display={'flex'}>

        <MDXEditor
        ref={ref}
            contentEditableClassName="markdown"
            className={colorMode === 'dark' ? 'dark-theme' : 'light-theme'}
            markdown={markdown}
            onChange={(value) => onChange(value)}
            readOnly={readOnly}
            plugins={[
                imagePlugin(),
                listsPlugin(),
                linkDialogPlugin(),
                tablePlugin(),
                markdownShortcutPlugin(),
                headingsPlugin(),
                quotePlugin(),
                toolbarPlugin({
                    toolbarContents: () => (
                        <>
                            <BlockTypeSelect />
                            <BoldItalicUnderlineToggles />
                            <Separator />
                            <InsertTable />
                            <CodeToggle />
                            <CreateLink />
                            <InsertImage />
                            <ListsToggle />
                            <Separator />
                            <UndoRedo />
                        </>
                    )
                })
            ]} />
        <Button variant={'ghost'} onClick={() => setReadOnly(!readOnly)}><FiEdit2 /></Button>
    </Box>
}

export default Editor